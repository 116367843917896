import { useEffect } from "react";
import { getBotAttendanceKey, getStatusTickets } from "../../services/Blip";
import { _logService } from "../../configs/LogConfig";
import { QueryStringService as _queryStringService } from "../../services/queryStringService/QueryStringService";
import { GetBotKey } from "../../utils/Base64Formatted";

export const SoundNotificationTicket = () => {
  let newTicketSound = new Audio("/notification-desk-sound.mp3");
  const _logger = _logService.getChildCategory("SoundNotificationTicket");
  let appData = _queryStringService.TryGetAppData();

  useEffect(() => {
    getBotAttendanceKey(
      GetBotKey(appData.routerData?.shortName, appData.routerData?.accessKey)
    )
      .then((response) => {
        let attendanceKey = response.data.resource;
        const interval = setInterval(() => {
          getTickets(attendanceKey);
        }, 30000);
        return () => clearInterval(interval);
      })
      .catch((exception) => {
        _logger.error(
          `Error trying to get resource containing overflow bot key`,
          exception
        );
      });
  }, []);

  const getTickets = (attendanceKey: string) => {
    getStatusTickets(attendanceKey)
      .then((response) => {
        if (response.data.resource.waiting > 0) {
          newTicketSound.play();
        }
      })
      .catch((exception) => {
        _logger.error(
          `Error when trying to check the number of tickets`,
          exception
        );
      });
  };

  return <></>;
};
