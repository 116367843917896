import create from "zustand";
import { Order } from "../components/Card/models/Order";

const emptyOrder: Order[] = [];

type State = {
  created: Order[];
  delivery: Order[];
  pickup: Order[];
  preparing: Order[];
  finished: Order[];
  update: (key: any, value: Order) => void;
  removeById: (key: any, id: Number) => void;
  populate: (key: any, value: Order) => any;
  notificationContent: { show: boolean; message: string; variant: string };
  updateNotification: (show: boolean, message: string, variant: string) => void;
};

export const useStore = create<State>((set) => ({
  created: emptyOrder,
  preparing: emptyOrder,
  delivery: emptyOrder,
  pickup: emptyOrder,
  finished: emptyOrder,
  update: (key, value) =>
    set((state) => ({
      //@ts-ignore
      [key]: [value, ...state[key]],
    })),
  removeById: (key, id) => {
    set((state) => ({
      //@ts-ignore
      [key]: state[key].filter((item: Order) => item.id !== id),
    }));
  },
  populate: (key, value) => {
    set(() => ({
      //@ts-ignore
      [key]: value,
    }));
  },
  showNotification: false,
  notificationContent: { show: false, message: "", variant: "success" },
  updateNotification: (show, message, variant) => {
    set(() => ({
      notificationContent: { show, message, variant },
    }));
  },
}));
