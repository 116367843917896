import { Divider, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import OrderCard from "../Card/OrderCard/OrderCard";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./StatusColumn.scss";

export const StatusColumn = ({
  statusName,
  cards,
  color,
  columnId,
  expandable,
  statusChange,
  expandableStatus,
}: Props) => {
  const [showList, setShowList] = useState(true);

  const listToggle = () => {
    if (expandable) {
      statusChange();
      setShowList(!showList);
    }
  };

  const ArrowSymbol = () => {
    return (
      <>
        {expandable ? (
          <IconButton style={{ color: "$color-content-default" }}>
            {expandableStatus ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        ) : (
          <></>
        )}
      </>
    );
  };

  const RenderCards = React.useMemo(() => {
    return (
      <div
        className={`${showList ? "show-list" : "hide-list"
          } scrollable-cards-list`}
      >
        {cards?.map((order, index) => {
          return (
            <div key={index} className={`${showList ? "" : "hide-card"}`}>
              <OrderCard
                columnId={columnId}
                order={order}
                address={order?.consumer?.address?.customerAddressFormatted}
                index={index}
                contactIdentity={order?.contactIdentity}
              />
            </div>
          );
        })}
      </div>
    );
  }, [cards]);

  const RenderDivider = () => {
    return expandable && !expandableStatus ? <></> : <Divider />;
  };

  return (
    <div
      className={`${showList ? "show-list" : "shrink-column"} column-container`}
    >
      <div className="column-header" onClick={() => listToggle()}>
        <div className="status-container" style={{ color }}>
          <div
            className="status-bubble"
            style={{ backgroundColor: color }}
          ></div>
          <div className="status-name">{statusName}</div>
          <ArrowSymbol />
        </div>
        <div className="cards-counter">({cards?.length})</div>
      </div>
      <RenderDivider />
      {RenderCards}
    </div>
  );
};

interface Props {
  cards: any[];
  color: string;
  columnId: number;
  expandable: boolean;
  expandableStatus?: boolean;
  statusChange?: any;
  statusName: string;
}
