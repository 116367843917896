export const NewOrder = {
  id: 1,
  title: "Novo",
  color: "#F6A721",
  expandable: false,
};

export const PrepareOrder = {
  id: 2,
  title: "Preparando",
  color: "#9933CC",
  expandable: false,
};

export const DeliveryOrder = {
  id: 3,
  title: "Entregando",
  color: "#00C6D7",
  expandable: true,
};

export const PickUpOrder = {
  id: 4,
  title: "Retirando",
  color: "#167491",
  expandable: true,
};

export const DoneOrder = {
  id: 5,
  title: "Concluído",
  color: "#21CC79",
  expandable: false,
};
