import axios from "axios";
import { BaseURL } from "../configs/Environment";

axios.defaults.baseURL = BaseURL;

export const getTemporaryToken = (staticToken: any) => {
  var postData = {
    staticToken: staticToken,
  };

  return axios.post("/api/v1/authentication", postData, {
    headers: {
      "content-type": "application/json,charset=UTF-8",
    },
  });
};

export const getOrders = (tempToken: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken}`;

  return axios.post("/api/v1/order/recover-last-orders");
};

export const getMerchantInfo = (merchantEmail: string, tempToken: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken}`;
  return axios.get(`/api/v1/merchant/details?email=${merchantEmail}`, {
    headers: {
      "content-type": "application/json,charset=UTF-8",
    },
  });
};

export const changeOrderStatus = (
  tempToken: string,
  orderId: number,
  newOrderStatus: string
) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken}`;

  const headers = { orderId, status: newOrderStatus };

  return axios.put(
    `/api/v1/order/change-status`,
    {},
    {
      headers: headers,
    }
  );
};

export const requestDelivery = (orderId: number, tempToken: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken}`;
  const headers = { orderId };
  return axios.post(
    `/api/v1/sode/request-delivery`,
    {},
    {
      headers: headers,
    }
  );
};

export const authorizeDelivery = (orderId: number, tempToken: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${tempToken}`;

  const headers = { orderId };
  return axios.post(
    `/api/v1/sode/authorize-delivery`,
    {},
    {
      headers: headers,
    }
  );
};
