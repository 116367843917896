import { Profile } from "./Profile";
import { RouterData } from "./RouterData";

export class AppData {
  accountToken?: string;
  profile?: Profile;
  routerData?: RouterData;
  tenantId?: string;

  constructor(
    accountToken?: string,
    profile?: Profile,
    routerData?: RouterData,
    tenantId?: string
  ) {
    this.accountToken = accountToken;
    this.profile = profile;
    this.routerData = routerData;
    this.tenantId = tenantId;
  }
}
