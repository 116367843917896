export const ConvertToReal = (priceNumber: Number) => {
  if (priceNumber) {
    return priceNumber.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  } else {
    return "R$ 0,00";
  }
};
