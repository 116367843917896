import { LogLevels } from "./../constants/LogLevels";
import { Environment } from "./../constants/Environments";

export const CurrentEnvironment =
  process.env.REACT_APP_ENVIRONMENT ?? Environment.Development;
export const CurrentLogLevel =
  process.env.REACT_APP_LOG_LEVEL ?? LogLevels.Info;
export const SegmentKey = process.env.REACT_APP_SEGMENT_KEY ?? "";
export const ModuleName = "Gestor de Pedidos Blip Foods";
export const BaseURL = process.env.REACT_APP_API_URL;
export const BlipBaseURL = `${process.env.REACT_APP_MSGING_URL}/commands`;
export const BlipFoodsAPIToken = process.env.REACT_APP_BLIP_FOODS_TOKEN;
