import React, { createContext, useState } from "react";

export type UserType = {
  token: string;
  name: string;
  imageUrl: string;
  id: string;
  botKey: string;
  phone: string;
  hasSode: boolean;
};

type PropsUserContext = {
  user: UserType;
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
};

const defaultUser = {
  user: {
    token: "",
    name: "",
    imageUrl: "",
    id: "",
    botKey: "",
    phone: "",
    hasSode: false,
  },
  setUser: () => {},
};

export const UserContext = createContext<PropsUserContext>(defaultUser);

export const ContextWrapper = (props: any) => {
  const [user, setUser] = useState(defaultUser.user);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};
