import { Alert, Snackbar } from "@mui/material";

export const Notification = ({
  showNotification,
  notificationType,
  handleClose,
  message,
}: Props) => {
  return (
    <Snackbar
      open={showNotification}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={handleClose}
    >
      <div className="alert-container">
        <Alert
          severity={notificationType}
          variant="filled"
          onClose={handleClose}
        >
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
};

interface Props {
  showNotification: boolean;
  notificationType: any;
  handleClose: (reason: any) => void;
  message: string;
}
