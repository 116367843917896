import base64 from "react-native-base64";

export const Base64Encode = (data: string) => {
  return base64.encode(data);
};

export const Base64Decode = (data?: string) => {
  return base64.decode(data!);
};

export const GetBotKey = (shortName?: string, accessKey?: string) => {
  return "Key " + Base64Encode(`${shortName}:${Base64Decode(accessKey)}`);
};
