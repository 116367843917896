import { _logService } from "../../configs/LogConfig";
import parser from "query-string";
import { AppData } from "./models/AppData";
import { Json } from "../../utils/Json";

const _logger = _logService.getChildCategory("QueryString");

function Parse(): object {
  let queryString: string = window.location.search;

  _logger.info(`Parsing "${queryString}"...`);

  let result = parser.parse(queryString);

  _logger.info(`Parsed "${queryString} with success"`);
  _logger.debug(Json.StringifyFormat(result), "Parsed object");

  return result;
}

function TryGetAppData(): AppData {
  const urlParams = new URLSearchParams(window.location.search);
  // @ts-ignore: Unreachable code error
  const value = urlParams.get("appData").replace(" ", "+");

  try {
    _logger.debug(value, "App Data as Base64");
    _logger.info("Parsing App Data");

    let parsedValue = decodeURIComponent(atob(value)).toString();

    _logger.debug(parsedValue, "App Data as Text");

    let parsedJson = JSON.parse(parsedValue);

    let result = new AppData(
      parsedJson.accountToken,
      parsedJson.profile,
      parsedJson.routerData,
      parsedJson.tenantId
    );

    _logger.debug(Json.StringifyFormat(result), "App Data casted as object");
    _logger.info("App data retrived with success!");
    return result;
  } catch (err) {
    throw new Error(`The informed value in AppData is not a valid Base64.`);
  }
}

export const QueryStringService = {
  Parse,
  TryGetAppData,
};
