export const NewOrderButton = {
  name: "Preparar",
  tracking: "blippacks-management-bf-order-prepare",
};

export const PrepareDeliveryButton = {
  name: "Entregar",
  tracking: "blippacks-management-bf-order-delivery",
};

export const PreparePickupButton = {
  name: "Retirar",
  tracking: "blippacks-management-bf-order-delivery",
};

export const DeliveryOrderButton = {
  name: "Concluir",
  tracking: "blippacks-management-bf-order-finishedDelivery",
};

export const PickupOrderButton = {
  name: "Concluir",
  tracking: "blippacks-management-bf-order-finishedDelivery",
};
