import { LocalStorageService as _localStorageService } from "./../localStorageService/LocalStorageService";
import { ModuleName } from "./../../configs/Environment";
import { DefaultUserTraits } from "./models/DefaultUserTraits";
import { ConfigurationService as _configurationService } from "./../configurationService/ConfigurationService";
import { Json } from "./../../utils/Json";
import { Environments } from "./../../constants/Environments";
import { _logService } from "../../configs/LogConfig";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { SegmentKey } from "../../configs/Environment";

const _logger = _logService.getChildCategory("Analytics");
const analytics = GetAnalyticsBrowser();
const _userTraitsKey = "userTraits";

function CheckDevelopmentEnvironment(): boolean {
  if (Environments.IsDevelopment() || Environments.IsStaging()) return true;

  _logger.debug(
    "The current environment is not productive. Analytics will not be logged."
  );
  return false;
}

/**
 * Get the analytics browser from segment key.
 * For development environment, undefined will be returned
 * @returns AnalyticsBrowser | undefined
 */
function GetAnalyticsBrowser(): AnalyticsBrowser | undefined {
  if (CheckDevelopmentEnvironment()) return undefined;

  return AnalyticsBrowser.load({ writeKey: SegmentKey });
}

/**
 * The Track method lets you record actions your users perform.
 * @param eventName The name of the event you’re tracking. You can read more about the track method and recommended event names.
 * @param traits A dictionary of properties for the event. If the event was 'Added to Cart', it might have properties like price and productType.
 * @returns void
 * 
 * @exemple 
 * _analyticsService.Track('Article Completed', {
        title: 'How to Create a Tracking Plan',
        course: 'Intro to Analytics',
    });
    
 */
function Track(
  eventName: string,
  traits?: any | undefined,
  includeDefaultTraits: boolean = true
): void {
  let log = _logger.getChildCategory("Track");

  if (includeDefaultTraits) {
    let userTraits = _localStorageService.Get(_userTraitsKey);
    traits = {
      ...traits,
      ...userTraits,
    };
  }

  if (CheckDevelopmentEnvironment()) {
    log.info("Adding track in analytics");
    log.debug(
      Json.StringifyFormat({
        eventName: eventName,
        traits: traits,
      }),
      "Track info"
    );
  } else {
    try {
      analytics!.track(eventName, traits);
    } catch (error) {
      log.error("Error on adding track on analytics.", error);
    }
  }
}

/**
 * The Page method lets you record page views on your website, along with optional extra information about the page viewed by the user.
 * @param category The category of the page. Useful for cases like ecommerce where many pages might live under a single category. Note: if you pass only one string to page it is assumed to be name. You must include a name to send a category.
 * @param name The name of the page.
 * @param properties A dictionary of properties of the page. Note: Analytics.js collects url, title, referrer and path are automatically. This defaults to a canonical url, if available, and falls back to document.location.href.
 * @returns void
 *
 * @exemple
 *  _analyticsService.Page('Pricing');
 *
 */
function Page(
  category?: string | undefined,
  name?: string | undefined,
  properties?: any | undefined,
  includeDefaultProperties: boolean = true
): void {
  if (CheckDevelopmentEnvironment()) return;

  let log = _logger.getChildCategory("Page");

  if (includeDefaultProperties) {
    let userTraits = _localStorageService.Get(_userTraitsKey);
    properties = {
      ...properties,
      ...userTraits,
    };
  }

  try {
    analytics!.page(category, name, properties);
    log.info("Adding page in analytics");
    log.debug(
      Json.StringifyFormat({
        category: category,
        name: name,
        properties: properties,
      }),
      "Page info"
    );
  } catch (error) {
    log.error("Error on adding page on analytics.", error);
  }
}

/**
 * Use the identify method to link your users and their actions, to a recognizable userId and traits.
 * @param id The database ID for the user. If you don’t know who the user is yet, you can omit the userId and just record traits. You can read more about identities in the identify reference.
 * @param traits A dictionary of traits you know about the user, like email or name. You can read more about traits in the identify reference.
 * @returns void
 * 
 * @exemple 
 *  _analyticsService.Identify({
        nickname: 'Amazing Grace',
        favoriteCompiler: 'A-0',
        industry: 'Computer Science'
    });
 * 
 * @exemple 
 *  _analyticsService.Identify('12091906-01011992', {
        name: 'Grace Hopper',
        email: 'grace@usnavy.gov'
    });
 * 
 */
function Identify(
  id?: string | undefined,
  traits?: any | undefined,
  includeDefaultTraits: boolean = true
): void {
  if (CheckDevelopmentEnvironment()) return;

  let log = _logger.getChildCategory("Identify");

  if (includeDefaultTraits) {
    let userTraits = _localStorageService.Get(_userTraitsKey);
    traits = {
      ...traits,
      ...userTraits,
    };
  }

  try {
    analytics!.identify(id, traits);
    log.info("Adding identify in analytics");
    log.debug(
      Json.StringifyFormat({
        id: id,
        traits: traits,
      }),
      "Identify info"
    );
  } catch (error) {
    log.error("Error on adding identify on analytics.", error);
  }
}

/**
 * Prepare the default traits for every track
 * @param botKey The bot key
 * @param userName The user name
 * @param userMail The user email
 * @returns DefaultUserTraits
 */
async function GetUserTraitsAsync(
  botKey: string,
  userName: string,
  userMail: string
): Promise<DefaultUserTraits | undefined> {
  let log = _logger.getChildCategory("DefaultProperties");

  try {
    let configurations = await _configurationService.GetConfigurationsAsync(
      botKey,
      userMail
    );
    let application = JSON.parse(configurations.resource.Application);
    let identifier = application.identifier;
    let tenant = application.settings.children[0].tenantId;
    let merchantName = userName;

    let request = new DefaultUserTraits(
      ModuleName,
      identifier,
      tenant,
      merchantName
    );

    return request;
  } catch (error) {
    log.error("Error on adding identify on analytics.", error);
  }
}

export const AnalyticsService = {
  Track,
  Page,
  Identify,
  GetUserTraitsAsync,
};
