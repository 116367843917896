import { Button, Card } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Trackings } from "../../../constants/Trackings";
import { AnalyticsService } from "../../../services/analyticsService/AnalyticsService";
import {
  authorizeDelivery,
  changeOrderStatus,
  getTemporaryToken,
  requestDelivery,
} from "../../../services/BlipFoods";
import { FormatDateAndHour } from "../../../utils/FormatDate";
import { ConvertToReal } from "../../../utils/FormatPrice";
import { UserContext } from "../../ContextWrapper/ContextWrapper";
import { ActionButton } from "../ActionButton/ActionButton";
import {
  DeliveryOrderButton,
  NewOrderButton,
  PickupOrderButton,
  PrepareDeliveryButton,
  PreparePickupButton,
} from "../constants/ActionButton";
import { Columns } from "../constants/Columns";
import { OrderDetails } from "../constants/OrderDetails";
import { Order } from "../models/Order";
import OrderGroups from "../OrderGroups/OrderGroups";
import OrderInfo from "../OrderInfo";
import "./OrderCard.scss";
import { formatPhoneNumber } from "../../../utils/FormatPhoneNumber";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";

const OrderCard = ({
  address,
  order,
  index,
  columnId,
  contactIdentity,
}: Props) => {
  const [hidden, setHidden] = useState(false);
  const [loadingColumnChange, setLoadingColumnChange] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (columnId === 5 || columnId === 4 || columnId === 3) {
      setHidden(true);
    }
  }, []);

  const ValidContentDeliveryAddress = () => {
    return (
      <>
        {address ? (
          <OrderInfo
            name={OrderDetails.shippingAddress}
            description={address}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const textButtonHidden = () => {
    return hidden ? "Ver detalhes" : "Ver menos";
  };

  const checkDeliveryType = () => {
    return order.shippingMode === Columns.delivery.name
      ? Columns.delivery.name
      : Columns.pickup.name;
  };

  const getColumnDestinationId = () => {
    if (columnId === 3 && order.merchant.integrationSode) {
      return "FinishedDelivery";
    }
    switch (columnId) {
      case 1:
        return Columns.preparing.name;
      case 2:
        return checkDeliveryType();
      default:
        return Columns.finished.name;
    }
  };

  const getColumnCurrentId = () => {
    switch (columnId) {
      case 1:
        return Columns.created.name;
      case 2:
        return Columns.preparing.name;
      case 3:
        return Columns.delivery.name;
      case 4:
        return Columns.pickup.name;
      default:
        return Columns.finished.name;
    }
  };

  const setTrackings = (traitsComplement?: any) => {
    if (columnId === 1) {
      AnalyticsService.Track(
        NewOrderButton.tracking,
        {
          numOrder: order.id,
          sodeIntegration: order.merchant.integrationSode,
          ...traitsComplement,
        },
        true
      );
    } else if (columnId === 2) {
      if (order.shippingMode === Columns.delivery.name) {
        AnalyticsService.Track(
          PrepareDeliveryButton.tracking,
          {
            numOrder: order.id,
            typeDelivery: order.shippingMode,
            sodeIntegration: order.merchant.integrationSode,
            ...traitsComplement,
          },
          true
        );
      } else {
        AnalyticsService.Track(
          PreparePickupButton.tracking,
          {
            numOrder: order.id,
            typeDelivery: order.shippingMode,
            sodeIntegration: order.merchant.integrationSode,
            ...traitsComplement,
          },
          true
        );
      }
    } else if (columnId === 3) {
      if (order.shippingMode === Columns.delivery.name) {
        AnalyticsService.Track(
          DeliveryOrderButton.tracking,
          {
            numOrder: order.id,
            typeDelivery: order.shippingMode,
            sodeIntegration: order.merchant.integrationSode,
            ...traitsComplement,
          },
          true
        );
      } else {
        AnalyticsService.Track(
          PickupOrderButton.tracking,
          {
            numOrder: order.id,
            typeDelivery: order.shippingMode,
            sodeIntegration: order.merchant.integrationSode,
            ...traitsComplement,
          },
          true
        );
      }
    }
  };

  const changeCardColumn = () => {
    setLoadingColumnChange(true);
    getTemporaryToken(user.token).then((tokenRes) => {
      changeOrderStatus(
        tokenRes.data.temporaryToken,
        order.id,
        getColumnDestinationId()
      )
        .then(() => {
          if (order.merchant.integrationSode) {
            if (getColumnDestinationId() === Columns.delivery.name) {
              requestDelivery(order.id, tokenRes.data.temporaryToken)
                .then(() => {
                  setTrackings({ status: true });
                })
                .catch((ex) => {
                  setTrackings({ status: false, error: ex.message });
                });
              setLoadingColumnChange(false);
              return;
            }

            if (getColumnDestinationId() === Columns.finishedDelivery.name) {
              authorizeDelivery(order.id, tokenRes.data.temporaryToken)
                .then(() => {
                  setTrackings({ status: true, motoboyAuthorized: true });
                })
                .catch((ex) => {
                  setTrackings({
                    status: false,
                    error: ex.message,
                    motoboyAuthorized: false,
                  });
                });
              setLoadingColumnChange(false);
              return;
            }
            setTrackings({ status: true });
          } else {
            setTrackings({ status: true });
          }
          setLoadingColumnChange(false);
        })
        .catch((ex) => {
          setTrackings({ status: false, error: ex.message });
          setLoadingColumnChange(false);
        });
    });
  };

  const DetailsProduct = () => {
    return (
      <div>
        {order.orderItems?.map((item, index) => {
          return (
            <div key={index} className="product">
              <div className="product-image">
                <img
                  className="product-image-border"
                  src={item.productImage}
                  alt=""
                  width="56px"
                  height="56px"
                />
              </div>

              <div className="full-width">
                <div className="product-details">
                  <div className="product-name">
                    {item.quantity}x {item.productName}
                  </div>

                  <div className="product-price">
                    {ConvertToReal(item.value)}
                  </div>
                </div>

                <div className="product-details-group">
                  <OrderGroups groups={item.orderGroups} />
                  <div className="separator-groups">
                    Observação:
                    <div key={index}>
                      <div className="group-details">{item.comment}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const RenderPayment = () => {
    if (order?.payment) {
      return (
        <OrderInfo
          name={OrderDetails.payment}
          description={getPaymentType(order.payment.type)}
        />
      );
    }
    return <></>;
  };

  const handleHideContentClick = () => {
    AnalyticsService.Track(Trackings.cardDetail, {
      numOrder: order.id,
      sodeIntegration: user.hasSode,
      columnName: getColumnCurrentId(),
      action: hidden ? "open" : "hide",
    });
    setHidden(!hidden);
  };

  const getPaymentType = (paymentType: string) => {
    return paymentType === "CREDIT_CARD" ? "Cartão" : paymentType;
  };

  return (
    <div className="content">
      <Card>
        <Stack spacing={2} sx={{ padding: 2 }}>
          <div className="order-created">
            <OrderInfo name={OrderDetails?.id} description={"#" + order?.id} />

            <OrderInfo
              name={OrderDetails?.createdAt}
              description={FormatDateAndHour(order?.createdAt)}
            />
          </div>

          <div>
            <OrderInfo
              name={OrderDetails?.updatedAt}
              description={FormatDateAndHour(order?.updatedAt)}
            />
          </div>

          <div>
            <OrderInfo
              name={OrderDetails.consumerName}
              description={
                order?.consumer?.firstName + " " + order?.consumer?.lastName
              }
            />
            <Stack direction="row" className="whatsapp-number-container">
              <WhatsAppIcon className="whatsapp-number-icon" />
              <span className="whatsapp-number-text">
                {formatPhoneNumber(contactIdentity)}
              </span>
            </Stack>
          </div>

          {hidden ? (
            <></>
          ) : (
            <>
              <DetailsProduct />

              <div className="order-bottom-details">
                <div className="order-comment">
                  <span className="order-comment-title">
                    {OrderDetails?.comment}
                  </span>
                  <span className="order-comment-details">
                    {order?.comment}
                  </span>
                </div>

                <div className="separator" />

                <div className="price-sub-total">
                  <span>Subtotal:</span>
                  <span>{ConvertToReal(order?.subtotalPrice)}</span>
                </div>

                <div className="price-sub-total">
                  <span>Frete:</span>
                  <span>{ConvertToReal(order?.shippingCost)}</span>
                </div>

                <div className="separator" />

                <div className="price-total">
                  <span>Total:</span>
                  <span>{ConvertToReal(order?.totalPrice)}</span>
                </div>
              </div>

              <ValidContentDeliveryAddress />

              <div>
                <RenderPayment />
              </div>
            </>
          )}

          <div className="buttons-card">
            <Button
              variant="text"
              style={{ margin: "auto", color: "$color-content-default" }}
              onClick={() => {
                handleHideContentClick();
              }}
            >
              {textButtonHidden()}
            </Button>

            <ActionButton
              columnId={columnId}
              isLoading={loadingColumnChange}
              shippingMode={order?.shippingMode}
              orderNumber={order?.id}
              changeColumn={() => changeCardColumn()}
            />
          </div>
        </Stack>
      </Card>
    </div>
  );
};

interface Props {
  order: Order;
  address: string;
  index: number;
  columnId: number;
  contactIdentity: string;
}
export default OrderCard;
