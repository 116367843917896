import "./index.scss";

const OrderInfo = ({ name, description }: Props) => {
  return (
    <div>
      <span className="title">{name}</span>
      <span className="details">{description}</span>
    </div>
  );
};

interface Props {
  name: string;
  description: any;
}

export default OrderInfo;
