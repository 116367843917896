import { BlipCommandBaseRequest } from "../../../base/models/BlipCommandBaseRequest";

const masterUri = `lime://${process.env.REACT_APP_BLIP_CONTRACT}master.hosting@msging.net/configuration`;
const builderUri = `lime://${process.env.REACT_APP_BLIP_CONTRACT}builder.hosting@msging.net/configuration`;

export class GetConfigurationRequest extends BlipCommandBaseRequest {
  metadata: {
    "blip_portal.email": string;
  };

  constructor(userMail: string, from?: string, pp?: string) {
    super(
      from !== undefined ? builderUri : masterUri,
      "GET",
      undefined,
      undefined,
      from,
      pp
    );
    this.metadata = { "blip_portal.email": userMail };
  }
}
