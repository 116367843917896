import "./Home.scss";
import {
  DeliveryOrder,
  DoneOrder,
  NewOrder,
  PickUpOrder,
  PrepareOrder,
} from "../../constants/Status";
import { useEffect, useState } from "react";
import { SignalrWrapper } from "../../components/SignalrWrapper/SignalrWrapper";
import { useStore } from "../../utils/ZustandConfig";
import { StatusColumn } from "../../components/statusColumn/StatusColumn";
import { Button, Dialog, DialogContentText, DialogTitle } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Stack } from "@mui/system";
import { SoundNotificationTicket } from "../../components/SoundNotificationTicket/SoundNotificationTicket";

function Home(): JSX.Element {
  const [expandDeliveryOrder, setExpandDeliveryOrder] = useState(true);
  const [expandPickUpOrder, setExpandPickUpOrder] = useState(true);
  const [classNameDeliveryOrder, setClassNameExpandDeliveryOrder] =
    useState("");
  const [classNamedPickUpOrder, setClassNameExpandPickUpOrder] = useState("");
  const [soundModalStatus, setSoundModalStatus] = useState(true);

  let [
    createdColumn,
    preparingColumn,
    deliveringColumn,
    pickingUpColumn,
    finishedColumn,
  ] = useStore((state) => [
    state.created,
    state.preparing,
    state.delivery,
    state.pickup,
    state.finished,
  ]);

  useEffect(() => {
    if (expandPickUpOrder && expandDeliveryOrder) {
      setClassNameExpandDeliveryOrder("");
      setClassNameExpandPickUpOrder("");
    } else if (expandPickUpOrder && !expandDeliveryOrder) {
      setClassNameExpandDeliveryOrder("column-max-height-unset");
      setClassNameExpandPickUpOrder("column-max-height-100");
    } else if (!expandPickUpOrder && !expandDeliveryOrder) {
      setClassNameExpandDeliveryOrder("");
      setClassNameExpandPickUpOrder("");
    } else {
      setClassNameExpandPickUpOrder("column-max-height-unset");

      setClassNameExpandDeliveryOrder("column-max-height-100");
    }
  }, [expandDeliveryOrder, expandPickUpOrder]);

  return (
    <SignalrWrapper>
      <SoundNotificationTicket />
      <div className="container">
        <Dialog
          onClose={() => setSoundModalStatus(false)}
          open={soundModalStatus}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ padding: "30px", width: "440px" }}
          >
            <NotificationsNoneOutlinedIcon
              sx={{ color: "gray", fontSize: 50 }}
            />
            <DialogTitle sx={{ padding: 0 }}>
              Escute os pedidos chegando.
            </DialogTitle>
            <DialogContentText sx={{ textAlign: "center", padding: 0 }}>
              Para usar o Gestor de Pedidos é preciso ativar o sinal sonoro
              nesta aba.
            </DialogContentText>
            <Button
              variant="contained"
              onClick={() => setSoundModalStatus(false)}
            >
              Ativar som
            </Button>
          </Stack>
        </Dialog>

        <div className="columns">
          <StatusColumn
            columnId={NewOrder.id}
            statusName={NewOrder.title}
            color={NewOrder.color}
            expandable={NewOrder.expandable}
            cards={createdColumn}
          />
        </div>
        <div className="columns">
          <StatusColumn
            columnId={PrepareOrder.id}
            statusName={PrepareOrder.title}
            color={PrepareOrder.color}
            expandable={PrepareOrder.expandable}
            cards={preparingColumn}
          />
        </div>
        <div className="column-shipping">
          <div className={`column-delivery ${classNameDeliveryOrder}`}>
            <StatusColumn
              columnId={DeliveryOrder.id}
              statusName={DeliveryOrder.title}
              color={DeliveryOrder.color}
              // TODO deixar expansivel
              expandable={false}
              cards={deliveringColumn}
              statusChange={() => setExpandDeliveryOrder(!expandDeliveryOrder)}
              expandableStatus={expandDeliveryOrder}
            />
          </div>

          <div className={`column-pickup ${classNamedPickUpOrder}`}>
            <StatusColumn
              columnId={PickUpOrder.id}
              statusName={PickUpOrder.title}
              color={PickUpOrder.color}
              // TODO deixar expansivel
              expandable={false}
              cards={pickingUpColumn}
              statusChange={() => setExpandPickUpOrder(!expandPickUpOrder)}
              expandableStatus={expandPickUpOrder}
            />
          </div>
        </div>

        <div className="columns">
          <StatusColumn
            columnId={DoneOrder.id}
            statusName={DoneOrder.title}
            color={DoneOrder.color}
            expandable={DoneOrder.expandable}
            cards={finishedColumn}
          />
        </div>
      </div>
    </SignalrWrapper>
  );
}

export default Home;
