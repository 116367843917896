import axios from "axios";
import { BlipBaseURL } from "../configs/Environment";
import { v4 as uuidv4 } from "uuid";

const axiosInstance = axios.create({
  baseURL: BlipBaseURL,
});

export const getStatusTickets = (botKey: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `${botKey}`;

  const params = {
    id: uuidv4(),
    to: "postmaster@desk.msging.net",
    method: "get",
    uri: "/monitoring/tickets",
  };

  return axiosInstance.post("", params);
};

export const getBotAttendanceKey = (routerKey?: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `${routerKey}`;

  const params = {
    id: uuidv4(),
    method: "get",
    uri: "/resources/transbordoKey",
  };

  return axiosInstance.post("", params);
};
