import { useEffect, useState } from "react";
import { ConvertToReal } from "../../../utils/FormatPrice";
import { GroupType } from "../constants/OrderDetails";
import { OrderGroup } from "../models/OrderGroup";
import { OrderGroupItem } from "../models/OrderGroupItem";
import "./OrderGroups.scss";

const OrderGroups = (props: Props) => {
  const arrayGroups: OrderGroupItem[] = [];
  const [infoLoaded, setInfoLoaded] = useState(false);

  const [oneChoice, setOneChoice] = useState(arrayGroups);
  const [multipleChoice, setMultipleChoice] = useState(arrayGroups);
  const [extras, setExtras] = useState(arrayGroups);
  const [observation, setObservation] = useState(arrayGroups);

  useEffect(() => {
    if (props.groups.length > 0 && !infoLoaded) {
      props.groups?.forEach((group) => {
        if (group.groupType === GroupType.oneChoice) {
          setOneChoice((current) => {
            return [...current, ...group.orderGroupItems];
          });
        }

        if (group.groupType === GroupType.multipleChoice) {
          setMultipleChoice((current) => {
            return [...current, ...group.orderGroupItems];
          });
        }

        if (group.groupType === GroupType.extras) {
          setExtras((current) => {
            return [...current, ...group.orderGroupItems];
          });
        }

        if (group.groupType === GroupType.observation) {
          setObservation((current) => {
            return [...current, ...group.orderGroupItems];
          });
        }
      });
      setInfoLoaded(true);
    }
  }, []);

  const RenderExtras = () => {
    return extras.length > 0 ? (
      <div className="separator-groups">
        Adicionais:
        {extras.map((groupExtras, index) => {
          return (
            <div key={index} className="group-space-value">
              <div className="group-details">
                {groupExtras.quantity + "x " + groupExtras.name}
              </div>
              {groupExtras.price > 0 && (
                <div className="product-price">
                  {ConvertToReal(groupExtras.price)}
                </div>
              )}
            </div>
          );
        })}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div>
      {oneChoice.map((groupOne, index) => {
        return (
          <div key={index} className="group-space-value">
            <div className="group-details">{groupOne.name}</div>
            {groupOne.price > 0 && (
              <div className="product-price">
                {ConvertToReal(groupOne.price)}
              </div>
            )}
          </div>
        );
      })}

      {multipleChoice.map((groupMultiple, index) => {
        return (
          <div key={index} className="group-space-value">
            <div className="group-details">{groupMultiple.name}</div>
            {groupMultiple.price > 0 && (
              <div className="product-price">
                {ConvertToReal(groupMultiple.price)}
              </div>
            )}
          </div>
        );
      })}
      <RenderExtras />

      {/* <div className="separator-groups">
        Observação:
        {observation.map((groupObservation, index) => {
          return (
            <div key={index}>
              <div className="group-details">{groupObservation.name}</div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

interface Props {
  groups: OrderGroup[];
}

export default OrderGroups;
