export const Columns: ColumnsInterface = {
  created: { name: "Created", notification: "" },
  preparing: {
    name: "Preparing",
    notification: "O pedido está sendo preparado!",
  },
  delivery: {
    name: "Delivery",
    notification: "O pedido está pronto para entrega!",
  },
  pickup: {
    name: "Pickup",
    notification: "O pedido está pronto para retirada!",
  },
  finished: { name: "Finished", notification: "O pedido foi concluído!" },
  finishedDelivery: {
    name: "FinishedDelivery",
    notification: "O pedido foi concluído!",
  },
};

export interface ColumnsInterface {
  created: ColumnDetail;
  preparing: ColumnDetail;
  delivery: ColumnDetail;
  pickup: ColumnDetail;
  finished: ColumnDetail;
  finishedDelivery: ColumnDetail;
}

export interface ColumnDetail {
  name: string;
  notification: string;
}
