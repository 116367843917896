import {
  DeliveryOrder,
  NewOrder,
  PickUpOrder,
  PrepareOrder,
} from "../../../constants/Status";
import {
  NewOrderButton,
  DeliveryOrderButton,
  PickupOrderButton,
  PrepareDeliveryButton,
  PreparePickupButton,
} from "../constants/ActionButton";
import { Button } from "@mui/material";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

export const ActionButton = ({
  columnId,
  changeColumn,
  isLoading,
  shippingMode,
  orderNumber,
}: Props) => {
  const CreateButton = (name: string, icon: any) => {
    const handleClick = () => {
      changeColumn();
    };

    return (
      <Button
        variant="outlined"
        disabled={isLoading}
        onClick={() => handleClick()}
        style={{ margin: "auto", color: "$color-primary" }}
        startIcon={icon}
      >
        {isLoading ? "Aguarde" : name}
      </Button>
    );
  };

  const RenderButton = () => {
    if (columnId === NewOrder.id) {
      return CreateButton(NewOrderButton.name, <AssignmentRoundedIcon />);
    } else if (columnId === PrepareOrder.id) {
      if (shippingMode === "Delivery") {
        return CreateButton(PrepareDeliveryButton.name, <SendRoundedIcon />);
      } else {
        return CreateButton(PreparePickupButton.name, <CheckRoundedIcon />);
      }
    } else if (columnId === DeliveryOrder.id) {
      return CreateButton(DeliveryOrderButton.name, <CheckRoundedIcon />);
    } else if (columnId === PickUpOrder.id) {
      return CreateButton(PickupOrderButton.name, <CheckRoundedIcon />);
    }
    return <></>;
  };
  return <RenderButton />;
};

interface Props {
  changeColumn: any;
  columnId: number;
  isLoading: boolean;
  shippingMode: string;
  orderNumber: number;
}
