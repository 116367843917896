import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getMerchantInfo,
  getOrders,
  getTemporaryToken,
} from "../../services/BlipFoods";
import { UserContext } from "../ContextWrapper/ContextWrapper";
import { HubConnection } from "@microsoft/signalr/dist/esm/HubConnection";
import { HubConnectionBuilder } from "@microsoft/signalr";
import base64 from "react-native-base64";
import { OrderItem } from "../Card/models/OrderItem";
import { useStore } from "../../utils/ZustandConfig";
import { Order } from "../Card/models/Order";
import { Notification } from "../Notification/Notification";
import { Columns, ColumnsInterface } from "../Card/constants/Columns";
import { Trackings } from "../../constants/Trackings";
import { AnalyticsService } from "../../services/analyticsService/AnalyticsService";
import { BaseURL, BlipFoodsAPIToken } from "../../configs/Environment";
import { _logMain } from "../../configs/LogConfig";
import { LocalStorageService as _localStorageService } from "../../services/localStorageService/LocalStorageService";
import { QueryStringService as _queryStringService } from "../../services/queryStringService/QueryStringService";
import { AnalyticsService as _analyticsService } from "../../services/analyticsService/AnalyticsService";
import { GetBotKey } from "../../utils/Base64Formatted";

export const SignalrWrapper = (props: Props) => {
  const { user, setUser } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [connection, setConnection] = useState<HubConnection>();
  let [
    updateColumn,
    populateColumn,
    notificationContent,
    updateNotification,
    removeCard,
  ] = useStore((state) => [
    state.update,
    state.populate,
    state.notificationContent,
    state.updateNotification,
    state.removeById,
  ]);
  const _logger = _logMain.getChildCategory("App");
  const _userTraits = "userTraits";
  let newOrderSound = new Audio("/new-order.mp3");

  useEffect(() => {
    getTemporaryToken(BlipFoodsAPIToken).then((tokenRes) => {
      let appData = searchParams.get("appData") as any;
      appData = appData.replace(" ", "+");
      appData = JSON.parse(base64.decode(appData));

      try {
        let appData = _queryStringService.TryGetAppData();

        _analyticsService
          .GetUserTraitsAsync(
            GetBotKey(
              appData.routerData?.shortName,
              appData.routerData?.accessKey
            ),
            appData.profile?.name!,
            appData.profile?.email!
          )
          .then((resp) => {
            _logger.info("Adding user traits to local storage");
            _localStorageService.Add(_userTraits, resp);
          });
      } catch (error) {
        _logger.error("Invalid app data!");
      }

      getMerchantInfo(appData.profile.email, tokenRes.data.temporaryToken).then(
        (merchantRes) => {
          let { botKey, id, imageUrl, name, token, phone, hasSode } =
            merchantRes.data;
          phone = `${phone}@wa.gw.msging.net`;

          getMerchantOrders(token);
          setUser({
            token,
            name,
            imageUrl,
            id,
            botKey,
            phone,
            hasSode,
          });

          // * Estabelece a conexão com o signalR
          const newConnection = new HubConnectionBuilder()
            .withUrl(`${BaseURL}hubs/notificationHub?merchantId=${id}`)
            .withAutomaticReconnect()
            .build();
          setConnection(newConnection);
          // * Tracking trigger change
          trackManagerOpening(merchantRes.data.hasSode);
        }
      );
    });
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          // * Nova order
          connection.on("ReceiveMessage", (newOrder) => {
            newOrderSound.play();
            const columnStatus = newOrder.statusId.toLowerCase();
            newOrder.orderItems = newOrder.orderItems.filter(
              (item: OrderItem) => item.productName !== "Frete"
            );
            AnalyticsService.Track(Trackings.newOrder, {
              numOrder: newOrder.id,
              sodeIntegration: user.hasSode,
            });
            updateColumn(columnStatus, newOrder);
          });

          // * Atualização de status da order
          connection.on("ReceiveNewStatus", (newStatus) => {
            let prevColumn = getPrevColumn(newStatus.statusId, newStatus);
            prevColumn = prevColumn?.toLowerCase();
            let columnStatus = "";
            if (
              newStatus.statusId === "FinishedDelivery" ||
              newStatus.statusId === "Finished"
            ) {
              columnStatus = "finished";
            } else {
              columnStatus = newStatus.statusId.toLowerCase();
            }

            newStatus.orderItems = newStatus.orderItems.filter(
              (item: OrderItem) => item.productName !== "Frete"
            );
            removeCard(prevColumn, newStatus.id);
            updateColumn(columnStatus, newStatus);
            setUpNotification(columnStatus);
          });
        })
        .catch((e) => console.error("Connection failed: ", e));
    }
  }, [connection]);

  const getPrevColumn = (column: string, order: Order) => {
    if (column === "FinishedDelivery") {
      return Columns.delivery.name;
    }

    switch (column) {
      case Columns.preparing.name:
        return Columns.created.name;
      case Columns.delivery.name:
        return Columns.preparing.name;
      case "PickUp":
        return Columns.preparing.name;
      case Columns.finished.name:
        if (order.shippingMode === "Delivery") {
          return Columns.delivery.name;
        } else {
          return Columns.pickup.name;
        }
    }
  };

  const getMerchantOrders = (token: string) => {
    getTemporaryToken(token).then((tokenRes) => {
      getOrders(tokenRes.data.temporaryToken).then(({ data }) =>
        Object.keys(data).forEach((key) => {
          data[key] = data[key].map((item: Order) => {
            item.orderItems = item.orderItems.filter(
              (item: OrderItem) => item.productName !== "Frete"
            );
            return item;
          });
          const columnName = checkColumnName(key);
          populateColumn(columnName, data[key]);
        })
      );
    });
  };

  const trackManagerOpening = (hasSodeIntegration: boolean) => {
    AnalyticsService.Track(Trackings.openManager, {
      sodeIntegration: hasSodeIntegration,
    });
  };

  const checkColumnName = (columnName: string) => {
    columnName = columnName.toLowerCase();
    if (columnName === "pickingup") {
      return "pickup";
    } else if (columnName === "delivering") {
      return "delivery";
    }
    return columnName;
  };

  const setUpNotification = (destinationColumn: string) => {
    updateNotification(
      true,
      Columns[destinationColumn as keyof ColumnsInterface].notification,
      "success"
    );
  };

  const closeNotification = (reason: any) => {
    updateNotification(false, "", "success");

    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <div>
      <Notification
        showNotification={notificationContent?.show}
        notificationType={notificationContent?.variant}
        message={notificationContent?.message}
        handleClose={(reason: any) => closeNotification(reason)}
      />
      {props.children}
    </div>
  );
};
interface Props {
  children: any;
}
