export const formatPhoneNumber = (contactIdentity: string) => {
  let firstPart;
  let lastPart;
  const phone = contactIdentity.replace("@wa.gw.msging.net", "");
  const DDI = phone.substring(0, 2);
  const DDD = phone.substring(2, 4);

  if (phone.length === 12) {
    firstPart = phone.substring(4, 8);
    lastPart = phone.substring(8, 12);
  } else if (phone.length === 13) {
    firstPart = phone.substring(5, 9);
    lastPart = phone.substring(9, 13);
  } else {
    return phone;
  }

  return `+${DDI} ${DDD} 9${firstPart} ${lastPart}`;
};
