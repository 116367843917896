import React, { createContext, useState } from "react";
import "./App.scss";
import { _logMain } from "../../configs/LogConfig";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../home/Home";
import { CurrentEnvironment, CurrentLogLevel } from "../../configs/Environment";
import { ContextWrapper } from "../../components/ContextWrapper/ContextWrapper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const MuiTheme = createTheme({
  typography: {
    h5: {
      color: "#202c44",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: "40px",
        },
      },
    },
  },
});

const _logger = _logMain.getChildCategory("App");

_logger.info(`Current environment: ${CurrentEnvironment}`);
_logger.info(`Current log level: ${CurrentLogLevel}`);

function App(): JSX.Element {
  return (
    <ContextWrapper>
      <ThemeProvider theme={MuiTheme}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </ContextWrapper>
  );
}

export default App;
