export const OrderDetails = {
  id: "Nº do pedido:",
  createdAt: "Realizado em:",
  updatedAt: "Data do status:",
  consumerName: "Cliente:",
  comment: "Observações do Pedido",
  shippingAddress: "Endereço de entrega:",
  payment: "Pedido pago com:",
};

export const GroupType = {
  oneChoice: "OneChoice",
  multipleChoice: "MultipleChoice",
  extras: "Extras",
  observation: "Observation",
};
